<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>
    
    <div class="py-4 container-fluid">

      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              <card
                title="Signed (last 90 days)"
                :value="metrics[0].Signed"
                percentage=""
                iconClass=""
                iconBackground=""
                detail=""
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                title="MAPDs (YTD)"
                :value="metrics[0].MAPDs"
                percentage=""
                iconClass=""
                iconBackground=""
                detail=""
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                title="Not at Carrier Level (YTD)"
                :value="metrics[0].NACL"
                percentage=""
                iconClass=""
                iconBackground=""
                percentageColor=""
                detail=""
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                title="Lost (YTD)"
                :value="metrics[0].Lost"
                percentage=""
                iconClass=""
                iconBackground=""
                hint=""
                directionReverse
              ></card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-7 mb-lg">
              <!-- line chart -->
              <div class="card z-index-2">
                <gradient-line-chart />
              </div>
            </div>
            <div class="col-lg-5">
              <carousel />
            </div>
          </div>
          <div v-if="(this.company =='Aspire' && !isAgent) || this.company != 'Aspire'" class="row mt-4">
            <div class="col-lg-7 mb-lg-0 mb-4">
              <div class="card">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-2">Top selling agents YTD</h6>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center">
                    <tbody>
                      <tr v-for="(sale, index) in sales" :key="index">
                        <td class="w-30">
                          <div class="px-2 py-1 d-flex align-items-center">
                            <div>
                              <img :src="sale.flag" alt="Country flag" />
                            </div>
                            <div class="ms-4">
                              <p class="mb-0 text-xs font-weight-bold">Agent:</p>
                              <h6 class="mb-0 text-sm">{{ sale.agent }}</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="mb-0 text-xs font-weight-bold">Enrollments:</p>
                            <h6 class="mb-0 text-sm">{{ sale.enrollments }}</h6>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="mb-0 text-xs font-weight-bold">MAPD's:</p>
                            <h6 class="mb-0 text-sm">{{ sale.mapds }}</h6>
                          </div>
                        </td>
                        <td>
                          <div class="text-center" hidden>
                            <p hidden class="mb-0 text-xs font-weight-bold">Value:</p>
                            <h6 hidden class="mb-0 text-sm">{{ "$" + sale.value }}</h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-5">

              <div class="col-lg-10 mb-lg-0 mb-4">
              <div class="card">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-2">Enrollments Today - {{ this.todays_totals }}</h6>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center">
                    <tbody>
                      <tr v-for="(sale, index) in sales_today" :key="index">
                        <td class="w-30">
                          <div class="px-2 py-1 d-flex align-items-center">
                            <div>
                              <img :src="sale.flag" alt="Country flag" hidden/>
                            </div>
                            <div class="ms-4">
                              <p class="mb-0 text-xs font-weight-bold">Agent:</p>
                              <h6 class="mb-0 text-sm">{{ sale.agent }}</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="mb-0 text-xs font-weight-bold">Enrollments:</p>
                            <h6 class="mb-0 text-sm">{{ sale.enrollments }}</h6>
                          </div>
                        </td>

                        <td>
                          <div class="text-center" hidden>
                            <p hidden class="mb-0 text-xs font-weight-bold">Value:</p>
                            <h6 hidden class="mb-0 text-sm">{{ "$" + sale.value }}</h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
            
            <div class="col-lg-5">
              <br>
              
              <div class="col-lg-10 mb-lg-0 mb-4">
              <div class="card">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-2">Enrollments (This AEP) - {{ this.last_7_days_total }}</h6>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center">
                    <tbody>
                      <tr v-for="(sale, index) in sales_7days" :key="index">
                        <td class="w-30">
                          <div class="px-2 py-1 d-flex align-items-center">
                            <div class="ms-4">
                              <p class="mb-0 text-xs font-weight-bold">Date:</p>
                              <h6 class="mb-0 text-sm">{{ new Date(sale.date).toLocaleDateString() }}</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="mb-0 text-xs font-weight-bold">Enrollments:</p>
                            <h6 class="mb-0 text-sm">{{ sale.enrollments }}</h6>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    </section>
  </main>
  <app-footer />

</template>

<script>

import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";

import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
//import CategoriesCard from "./components/CategoriesCard.vue";

const company = sessionStorage.getItem("company");

export default {
  name: "dashboard",
  data() {
    return {
      metrics: [{ 
        'metric1': 0,
        'metric2': 0,
        'metric3': 0,
        'metric4': 0
    }],
    company: '',
    sales: [],
    sales_today: [],
    sales_7days: [],
    todays_totals: 0,
    role: sessionStorage.getItem('role')
    };
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    async getData() {
        let response = await fetch("/apis/metrics");
        this.metrics = await response.json();
    },
    async getData2() {
        let response = await fetch("/apis/sales");
        this.sales = await response.json();
    },
    async getData3() {
        let response = await fetch("/apis/sales_today");
        this.sales_today = await response.json();
    },
    async getData4() {
        let response = await fetch("/apis/todays_totals");
        this.todays_totals = await response.json();
    },
    async getData5() {
        let response = await fetch("/apis/sales_7days");
        this.sales_7days = await response.json();
    },
  },
  components: {
    Navbar,
    Card,
    GradientLineChart,
    Carousel,
    //CategoriesCard
  },
  created() {
    this.company = sessionStorage.getItem("company");
    this.getData();
    this.getData2();
    this.getData3();
    this.getData4();
    this.getData5();
  },
};
</script>
